<template>
    <v-container class="pl-0">
        <v-text-field class="pt-1 mt-0"
            v-model="customer"
            label="Customer"
        />
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    customer: {
      get() {
        if (this.gateEntry.equipment.customer) {
          return this.gateEntry.equipment.customer.name;
        }
        return '';
      },
      set(customer) {
        this.setCustomer(customer);
      },
    },
  },
  methods: {
    ...mapActions('gate', ['setCustomer']),
  },
};
</script>
