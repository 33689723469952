<template>
  <div>
    <VerifyEquipmentEntryDialog @verify-equipment-dialog-closed="verifyEquipmentDialogClosed" />
    <v-combobox
        solo
        clearable
        @click:clear="clearForm"
        :items="knownEquipment"
        v-model="equipmentNumber"
        item-text="number"
        label="Equipment Number Search"
        :menu-props="{maxHeight: 150}"
    >
      <template v-slot:no-data>
        <v-list-item>
          <span> Does Not Exist</span>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :input-value="selected"
          label
        >
          <span class="pr-2"> {{ item.number }} </span>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-chip label> {{ item.number }} </v-chip>
        <span label class="ml-1"> Type: {{ item.type }} |</span>
        <span label class="ml-1"> Size: {{ item.size }} |</span>
        <span label class="ml-1"> State: {{ item.state }} |</span>
        <v-spacer></v-spacer>
      </template>
    </v-combobox>
    <v-form ref="formEntry">
      <v-container fluid>
        <v-row>
          <EquipmentTypeEntry
            v-bind:disabled="!isNewEquipment"
          />
        </v-row>
        <v-row>
          <EquipmentStateEntry />
        </v-row>
        <v-row>
          <EquipmentNumberEntry
            v-bind:disabled="!isNewEquipment"
            v-bind:allowAutocomplete=false
          />
        </v-row>
        <v-row>
          <EquipmentSizeEntry />
        </v-row>
        <v-row class="pt-0 mt-0">
          <EquipmentCarrierEntry />
        </v-row>
        <v-row>
          <v-col class="pl-0 pt-0 mt-0">
              <EquipmentBookingNumberEntry />
          </v-col>
          <v-col class="pl-0 pt-0 mt-0">
            <EquipmentCustomerEntry />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-0 py-0 my-0" md="3">
            <v-text-field
              disabled
              :rules="equipmentLocationRules"
              v-model="spot"
              label="Spot Number *"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <submit-button v-if="!showEditEquipmentDialog" @submit="submit" class="mt-n1 mb-0 pt-n1 mb-0"></submit-button>
            <edit-location-equipment-dialog-buttons v-else @equipmentConfirmed="equipmentConfirmed" @update="update" class="mt-n1 mb-0 pt-n1 mb-0"></edit-location-equipment-dialog-buttons>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';
import EditLocationEquipmentDialogButtons from './editLocationEquipmentDialogButtons.vue';
import EquipmentTypeEntry from './equipmentTypeEntry.vue';
import EquipmentStateEntry from './equipmentStateEntry.vue';
import EquipmentNumberEntry from './equipmentNumberEntry.vue';
import EquipmentSizeEntry from './equipmentSizeEntry.vue';
import EquipmentCarrierEntry from './equipmentCarrierEntry.vue';
import EquipmentBookingNumberEntry from './equipmentBookingNumberEntry.vue';
import EquipmentCustomerEntry from './equipmentCustomerEntry.vue';
import SubmitButton from './submitButton.vue';
import VerifyEquipmentEntryDialog from './verifyEquipmentEntryDialog.vue';

export default {
  components: {
    EditLocationEquipmentDialogButtons,
    EquipmentTypeEntry,
    EquipmentStateEntry,
    EquipmentNumberEntry,
    EquipmentSizeEntry,
    EquipmentCarrierEntry,
    EquipmentBookingNumberEntry,
    EquipmentCustomerEntry,
    SubmitButton,
    VerifyEquipmentEntryDialog,
  },
  data: () => ({
    newEquipment: true,
    isOnYard: false,
    isLostEquipment: false,
    equipmentLocationRules: [rules.rules.required],
  }),
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    ...mapGetters('equipment', ['equipmentNumberSearch', 'equipmentWithLocation']),
    ...mapGetters('location', ['equipmentByNumber', 'showEditEquipmentDialog', 'showEditEquipmentDialog']),
    ...mapGetters('check', ['lostEquipmentByEquipmentNumber', 'lostEquipment', 'selectedCheck']),
    knownEquipment() {
      if (this.equipmentWithLocation) {
        const equipmentOnYardList = this.equipmentWithLocation;
        if (this.lostEquipment) {
          const lostEquipmentList = this.lostEquipment.map((a) => a.equipment);
          const equipmentListArray = [...equipmentOnYardList, ...lostEquipmentList];
          return equipmentListArray;
        }
        return equipmentOnYardList;
      }
      return [];
    },
    equipmentNumber: {
      get() {
        return this.equipmentNumberSearch;
      },
      set(value) {
        if (value != null) {
          this.setEquipmentNumberSearch(value.number);
        }
      },
    },
    spot: {
      get() {
        return this.gateEntry.location.displayName;
      },
      set(spot) {
        this.setLocationDisplayName(spot);
      },
    },
    isNewEquipment() {
      if (this.gateEntry.equipment.id !== '') {
        return false;
      }
      return this.newEquipment;
    },
  },
  methods: {
    ...mapActions('gate', ['resetState', 'setLocationDisplayName', 'setEquipment']),
    ...mapActions('location', ['updateEquipment', 'updateEquipment']),
    ...mapActions('check', ['addConfirmedEquipment']),
    ...mapActions('equipment', ['setEquipmentNumberSearch', 'setShowVerifyEquipmentDialog']),
    clearForm() {
      this.setEquipmentNumberSearch('');
    },
    setCheckEntry(equipmentSearchItem) {
      this.newEquipment = false;
      this.setEquipment({
        id: equipmentSearchItem.equipment.id,
        type: equipmentSearchItem.equipment.type,
        number: equipmentSearchItem.equipment.number,
        size: equipmentSearchItem.equipment.size,
        state: equipmentSearchItem.equipment.state,
        customer: equipmentSearchItem.equipment.customer,
        bookingNumber: equipmentSearchItem.equipment.bookingNumber,
        carrier: {
          scac: equipmentSearchItem.equipment.carrier.scac,
          name: equipmentSearchItem.equipment.carrier.name,
        },
      });
    },
    verifyEquipmentDialogClosed() {
      this.$refs.formEntry.resetValidation();
    },
    submit() {
      const isValid = this.$refs.formEntry.validate();
      if (isValid) {
        this.setShowVerifyEquipmentDialog(true);
      }
    },
    async equipmentConfirmed() {
      const isValid = this.$refs.formEntry.validate();
      if (isValid) {
        this.$refs.formEntry.resetValidation();
        const { location, equipment } = this.gateEntry;
        if (equipment.arrivalDate === null) {
          equipment.arrivalDate = new Date().toISOString();
        }
        const request = {
          location,
          equipment,
          checkID: this.selectedCheck.id,
        };

        await this.addConfirmedEquipment(request);
      }
    },
    async update() {
      const isValid = this.$refs.formEntry.validate();
      if (isValid) {
        this.$refs.formEntry.resetValidation();
        await this.updateEquipment({
          location: _.cloneDeep(this.gateEntry.location),
          equipment: _.cloneDeep(this.gateEntry.equipment),
        });
      }
    },
  },
  watch: {
    equipmentNumber(value) {
      if (value) {
        let equipment = this.equipmentByNumber(value);
        if (!equipment) {
          const lostEquipment = this.lostEquipmentByEquipmentNumber(value);
          if (lostEquipment) {
            equipment = this.lostEquipmentByEquipmentNumber(value).equipment;
          }
        }

        if (equipment !== undefined) {
          this.setCheckEntry({ equipment });
        }
      } else {
        this.newEquipment = true;
        this.setEquipment({
          id: '',
          type: '',
          number: '',
          size: '',
          state: '',
          customer: '',
          bookingNumber: '',
          carrier: {
            scac: '',
            name: '',
          },
        });
      }
    },
  },
  beforeDestroy() {
    this.resetState();
  },
};
</script>
