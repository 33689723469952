<template>
    <v-container class="pl-0">
        <v-text-field class="pt-1 mt-0"
            v-model="bookingNumber"
            label="Booking #"
        />
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    bookingNumber: {
      get() {
        return this.gateEntry.equipment.bookingNumber;
      },
      set(bookingNumber) {
        this.setBookingNumber(bookingNumber);
      },
    },
  },
  methods: {
    ...mapActions('gate', ['setBookingNumber']),
  },
};
</script>
