<template>
  <v-dialog :persistent="true" v-model="showDialog" max-width="600">
    <v-card>
      <div v-if="isLoading">
        <h2 class="text-center">Saving...</h2>
        <v-progress-circular
          indeterminate
          size="100"
          class="offset-md-5"
          style="overflow: hidden;"
          ></v-progress-circular>
      </div>
      <div v-if="!isLoading">
        <v-card-title> Does the information below look correct? </v-card-title>

        <v-card-text v-if="gateEntry.direction === IN">
          <span><strong>Equipment:</strong> {{ gateEntry.equipment.type }}</span>
          <br />
          <span><strong>Direction:</strong> {{ gateEntry.direction }}</span>
          <br />
          <span><strong>Loaded Status:</strong> {{ gateEntry.equipment.state }}</span>
          <br />
          <span><strong>Equipment Number:</strong></span><span> {{ gateEntry.equipment.number }}</span>
          <br />
          <span><strong>Size:</strong> {{ gateEntry.equipment.size }}</span>
          <br />
          <span v-if="gateEntry.equipment.carrier.scac"><strong>SCAC:</strong> {{ gateEntry.equipment.carrier.scac }}<br /></span>
          <span v-if="gateEntry.equipment.carrier.name"><strong>Carrier:</strong> {{ gateEntry.equipment.carrier.name }}<br /></span>
          <span v-if="gateEntry.equipment.bookingNumber"><strong>Booking #:</strong> {{ gateEntry.equipment.bookingNumber }}<br /></span>
          <span v-if="gateEntry.equipment.customer.name"><strong>Customer:</strong> {{ gateEntry.equipment.customer.name }}<br /></span>
          <span v-if="gateEntry.direction === IN"><strong>Spot Number:</strong> {{ gateEntry.location.displayName }}</span>
        </v-card-text>

        <v-card-text v-if="gateEntry.direction === OUT">
          <span><strong>Equipment:</strong> {{ gateEntry.equipment.type }}</span>
          <br />
          <span><strong>Direction:</strong> {{ gateEntry.direction }}</span>
          <br />
          <span><strong>Equipment Number:</strong></span><span> {{ gateEntry.equipment.number }}</span>
          <br />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="save" color="primary" outlined @click="save"> Save </v-btn>
          <v-btn color="primary" outlined @click="showDialog = false"> Close </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import direction from '../constants/direction';

export default {
  created() {
    this.IN = direction.IN;
    this.OUT = direction.OUT;
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set(value) {
        if (!value) {
          this.setShowVerifyEquipmentDialog(false);
        }
      },
    },
    ...mapGetters({ gateEntry: 'gate/gateEntry', show: 'equipment/showVerifyEquipmentDialog', isLoading: 'gate/isLoading' }),
  },
  methods: {
    ...mapActions('gate', ['saveEntry', 'resetState']),
    ...mapActions({ resetExpectedGateActivityState: 'expectedGateActivity/resetState', getExpectedGateActivities: 'expectedGateActivity/getExpectedGateActivities' }),
    ...mapActions('equipment', ['setShowVerifyEquipmentDialog']),
    async save() {
      await this.saveEntry(this.gateEntry);
      this.resetState();
      this.resetExpectedGateActivityState();
      this.showDialog = false;
      this.getExpectedGateActivities();
      this.$emit('verify-equipment-dialog-closed');
    },
  },
};
</script>
