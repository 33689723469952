<template>
<div>
  <yardCheck />
</div>
</template>

<script>

import yardCheck from '@/components/yardCheck.vue';

export default {
  name: 'YardCheckView',
  components: { yardCheck },
};
</script>
