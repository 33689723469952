<template>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="primary" outlined @click="saveClicked" v-if="!gateEntry.equipment.id"> Save </v-btn>
    <v-btn color="primary" outlined @click="updateClicked" v-if="gateEntry.equipment.id"> Update </v-btn>
    <v-btn color="primary" outlined @click="closeClicked"> Close </v-btn>
  </v-card-actions>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      gateEntry: 'gate/gateEntry',
    }),
    ...mapGetters('check', ['lostEquipmentByEquipmentNumber']),
    ...mapGetters('location', ['locationByEquipmentNumberAndType']),
  },
  methods: {
    ...mapActions({
      setShowEditEquipmentDialog: 'location/setShowEditEquipmentDialog',
      setEquipmentNumberSearch: 'equipment/setEquipmentNumberSearch',
      raiseError: 'alert/raiseError',
    }),
    closeClicked() {
      this.setEquipmentNumberSearch('');
      this.setShowEditEquipmentDialog(false);
    },
    async updateClicked() {
      this.$emit('update');
      this.setEquipmentNumberSearch('');
    },
    saveClicked() {
      const existingLocation = this.locationByEquipmentNumberAndType(this.gateEntry.equipment.number, this.gateEntry.equipment.type);
      if (existingLocation) {
        this.setShowEditEquipmentDialog(false);
        this.setEquipmentNumberSearch('');
        this.raiseError(`Cannot add equipment ${existingLocation.equipment[0].number}. The current YMS locations is: ${existingLocation.groups}, Location ${existingLocation.displayName}`);
        return;
      }
      this.$emit('equipmentConfirmed');
      this.setEquipmentNumberSearch('');
    },
  },
};
</script>
