<template>
  <div>
    <verify-yard-check-dialog></verify-yard-check-dialog>
      <v-container class="ma-0" fluid>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col md="3" class="pr-0">
                Yard Check&nbsp;&nbsp;
                <v-btn
                  v-if="showStartCheckBtn"
                  id="startButton"
                  @click="start"
                  raised
                  color="primary"
                >
                  <v-icon style="color: white">play_arrow</v-icon>Start New Check
                </v-btn>
                <v-btn
                  v-if="!showStartCheckBtn && !isPageLoading"
                  @click="stop"
                  color="secondary"
                >
                  <v-icon style="color: white">stop</v-icon>Complete Check
                </v-btn>
              </v-col>
              <v-col md="3" v-if="isLoading">
                <v-progress-circular
                  indeterminate
                  size="100"
                  class="offset-md-5"
                  style="overflow: hidden;"
                  v-if="isLoading"
                ></v-progress-circular>
              </v-col>
              <v-col md="3" class="pl-0 pr-14" v-if="hasOngoingChecks && !isPageLoading">
                <span>On-going Checks</span>
                <v-select
                  :items="activeChecks"
                  :item-text="(item) => `Started on ${formatDate(item.startTime)}`"
                  v-model="checkSelected"
                  return-object
                  label="Select a check..."
                >
                  <template v-slot:append-item v-if="!this.showStartCheckBtn">
                    <v-divider class="mb-2"></v-divider>
                    <v-btn :block="true" @click="start" color="primary">
                      <v-icon style="color: white">play_arrow</v-icon>Start New
                      Check
                    </v-btn>
                  </template>
                </v-select>
              </v-col>
              <v-col :md="hasOngoingChecks ? 6 : 9">
                <v-row>
                  <v-text-field
                    v-model="searchTerm"
                    append-icon="mdi-magnify"
                    label="Search"
                    v-if="!isPageLoading"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-row>
                <v-row class="pt-3">
                  <v-btn
                    v-if="userHasAdminRole"
                    @click="deleteLostEquipmentClicked()">
                    Delete Lost Equipment
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card-title>
            <v-row class="mx-0 px-0 my-0 py-0">
              <v-col>
                <v-slide-group
                  v-if="checkSelected.id"
                  v-model="pageGroup"
                  show-arrows
                  active-class="neutral black--text"
                  center-active
                  mandatory
                >
                  <v-slide-item
                    class="mx-.5"
                    v-for="group in groupNames"
                    :key="group"
                    v-slot="{active}"
                  >
                    <v-btn
                      class="black--text"
                      :input-value="active"
                      small
                      @click="groupSelected(group)">
                      {{group}}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-col>
            </v-row>
          <delete-lost-equipment-dialog></delete-lost-equipment-dialog>
          <edit-location-equipment-dialog></edit-location-equipment-dialog>
          <v-form ref="formEntry">
            <v-data-table
              id="checkDataTable"
              class="data-table"
              loading-text="Loading... Please wait"
              hide-default-footer
              height="65vh"
              :headers="headers"
              :items="tableData"
              :expanded="expandedCheckEntries"
              :fixed-header="true"
              :loading="isPageLoading"
              :item-key="'id'"
              :server-items-length="100"
              v-if="checkSelected.id"
              @click:row="(item, slot) => {slot.expand(!slot.isExpanded); modifyClicked(item)}"
            >
              <template v-slot:item.location.displayName="{ item }">
                <h2>{{ item.location.displayName }}</h2>
              </template>
              <template v-slot:item.equipment.number="{ item }">
                <b v-if="!canHoldMultipleEquipment(item)">{{ item.equipment.number }}</b>
                <b v-else>TOTAL: {{ item.location.equipmentCapacity }} SPOTS</b>
              </template>
              <template v-slot:item.equipment.type="{ item }">
                <span v-if="!canHoldMultipleEquipment(item)">{{ item.equipment.type }}</span>
                <b v-else>OCCUPIED: {{ item.location.equipment.length }}</b>
              </template>
              <template v-slot:item.equipment.size="{ item }">
                <span v-if="!canHoldMultipleEquipment(item)">{{ item.equipment.size }}</span>
                <b v-else>EMPTY: {{ item.location.equipmentCapacity - item.location.equipment.length }}</b>
              </template>
              <template
                v-slot:expanded-item="{ headers, item }"
                color="primary"
              >
                <td
                  id="expanded-area"
                  :colspan="headers.length"
                  bgcolor="white"
                >
                  <location-check
                    :checkEntry="item"
                    v-if="canHoldMultipleEquipment(item)"
                  >
                  </location-check>
                  <v-btn
                    v-if="shouldShowConfirmButton(item)"
                    class="mr-4 mb-4 mt-0"
                    color="primary"
                    min-width="8vw"
                    @click="confirmClicked(item.equipment)"
                    :disabled="canCheck"
                  >
                    Confirm
                  </v-btn>
                  <v-btn
                    v-if="shouldShowConfirmLocationButton(item)"
                    class="mr-4 mb-4 mt-0"
                    color="primary"
                    min-width="8vw"
                    @click="confirmLocationClicked(item)"
                    :disabled="canCheck"
                  >
                    Confirm
                  </v-btn>
                  <v-btn
                    v-if="shouldShowLostButton(item)"
                    class="mr-4 mb-4 mt-0"
                    color="secondary"
                    min-width="8vw"
                    @click="lostClicked(item.equipment)"
                    :disabled="canCheck"
                  >
                    Lost
                  </v-btn>
                  <v-btn
                    v-if="shouldShowEditButton(item)"
                    class="mr-4 mb-4 mt-0"
                    color="neutral"
                    min-width="8vw"
                    @click="editClicked(item)"
                    :disabled="canCheck"
                  >
                    Edit
                  </v-btn>
                  <v-btn
                    v-if="shouldShowAddButton(item)"
                    class="mr-4 mb-4 mt-0"
                    color="neutral"
                    min-width="8vw"
                    @click="addClicked(item)"
                    :disabled="canCheck"
                  >
                    Add
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-form>
        </v-card>
      </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import dateMixins from '../mixins/dateMixins';
import DeleteLostEquipmentDialog from './deleteLostEquipmentDialog.vue';
import EditLocationEquipmentDialog from './editLocationEquipmentDialog.vue';
import LocationCheck from './locationCheck.vue';
import carrierMixin from '../mixins/carrierMixins';
import checkMixins from '../mixins/checkMixins';
import VerifyYardCheckDialog from './verifyYardCheckDialog.vue';
import checkStatuses from '../constants/checkStatuses';
import direction from '../constants/direction';

export default {
  mixins: [dateMixins, carrierMixin, checkMixins],
  components: {
    EditLocationEquipmentDialog, VerifyYardCheckDialog, DeleteLostEquipmentDialog, LocationCheck,
  },
  data: () => ({
    headers: [
      { text: 'Location', value: 'location.displayName' },
      { text: 'Status', value: 'status' },
      { text: 'Equipment Number', value: 'equipment.number' },
      { text: 'Type', value: 'equipment.type' },
      { text: 'Size', value: 'equipment.size' },
      { text: 'State', value: 'equipment.state' },
      { text: 'SCAC', value: 'equipment.carrier.scac' },
      { text: 'Carrier', value: 'equipment.carrier.name' },
      { text: 'Booking #', value: 'equipment.bookingNumber' },
      { text: 'Customer', value: 'equipment.customer.name' },
    ],
    interval: null,
  }),
  computed: {
    tableData() {
      if (this.searchTerm.length > 1) {
        return this.filteredCheckEntries(this.searchTerm);
      }
      return this.checkEntriesByLocationGroup(this.selectedGroup);
    },
    showStartCheckBtn() {
      return _.isEmpty(this.selectedCheck) && !this.isPageLoading;
    },
    pageGroup: {
      get() {
        return this.groupNames.indexOf(this.selectedGroup);
      },
      set(group) {
        if (group) {
          this.setSelectedGroup(group);
        }
      },
    },
    isPageLoading() {
      return this.isLoading || this.locationIsLoading;
    },
    isAllRecordsAccountedFor() {
      return this.amountOfRecordsUnaccountedFor === 0;
    },
    userHasAdminRole() {
      if (this.user) {
        return this.user.roles.includes('Admin');
      }
      return false;
    },
    amountOfRecordsUnaccountedFor() {
      return this.checkEntries.length - (this.confirmedEquipment.length + this.confirmedLocations.length);
    },
    checkSelected: {
      get() {
        return this.selectedCheck;
      },
      set(newValue) {
        this.setSelectedCheck(newValue);
      },
    },
    searchTerm: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setFilter(value);
      },
    },
    ...mapGetters('check', [
      'activeChecks',
      'selectedCheck',
      'canCheck',
      'hasOngoingChecks',
      'lostEquipment',
      'confirmedEquipment',
      'confirmedLocations',
    ]),
    ...mapGetters({
      filter: 'checkView/filter',
      selectedGroup: 'checkView/selectedGroup',
      expandedCheckEntries: 'checkView/expandedCheckEntries',
      groupNames: 'location/groupNames',
      equipmentLocation: 'location/equipmentLocation',
      isYardSocketConnected: 'webSocket/isYardSocketConnected',
      checkEntries: 'checkView/checkEntries',
      filteredCheckEntries: 'checkView/filteredCheckEntries',
      checkEntriesByLocationGroup: 'checkView/checkEntriesByLocationGroup',
    }),
    ...mapGetters('account', ['user']),
  },
  methods: {
    ...mapActions({
      startCheck: 'check/startCheck',
      addConfirmedEquipment: 'check/addConfirmedEquipment',
      removeConfirmedEntry: 'check/removeConfirmedEquipment',
      setSelectedCheck: 'check/setSelectedCheck',
      getActiveChecks: 'check/getActiveChecks',
      getLostEquipment: 'check/getLostEquipment',
      refreshCheckEntries: 'check/refreshCheckEntries',
      setShowDeleteLostEquipmentDialog: 'check/setShowDeleteLostEquipmentDialog',
      setFilter: 'checkView/setFilter',
      addLostEquipment: 'check/addLostEquipment',
      removeLostEquipment: 'check/removeLostEquipment',
      setShowVerifyYardCheckDialog: 'verifyYardCheck/setShowDialog',
      raiseInformation: 'alert/raiseInformation',
      addConfirmedLocation: 'check/addConfirmedLocation',
      removeConfirmedLocation: 'check/removeConfirmedLocation',
      setSelectedGroup: 'checkView/setSelectedGroup',
      setIsLoading: 'check/setIsLoading',
    }),
    canHoldMultipleEquipment(item) {
      if (item.location.equipmentCapacity > 1) {
        return true;
      }
      return false;
    },
    shouldShowConfirmButton(item) {
      return item.status !== checkStatuses.CONFIRMED && item.equipment.id && item.location.equipmentCapacity === 1;
    },
    shouldShowConfirmLocationButton(item) {
      return item.status !== checkStatuses.CONFIRMED && !item.equipment.id && item.location.equipmentCapacity === 1;
    },
    shouldShowLostButton(item) {
      return item.status !== checkStatuses.CONFIRMED && item.equipment.id && item.location.equipmentCapacity === 1;
    },
    shouldShowEditButton(item) {
      let isEditable = false;
      const hasUnsetStatus = !item.status || item.status === '';
      if (hasUnsetStatus) {
        isEditable = true;
      }
      return isEditable && item.equipment.id !== '' && item.location.equipmentCapacity === 1;
    },
    shouldShowAddButton(item) {
      let isEditable = false;
      const hasUnsetStatus = !item.status || item.status === '';
      if (hasUnsetStatus) {
        isEditable = true;
      }
      return isEditable && item.equipment.id === '' && item.location.equipmentCapacity === 1;
    },
    start() {
      this.startCheck();
    },
    stop() {
      if (this.isAllRecordsAccountedFor) {
        this.setShowVerifyYardCheckDialog(true);
      } else {
        this.raiseInformation(`${this.amountOfRecordsUnaccountedFor} spot(s) remaining unaccounted for`);
      }
    },
    confirmLocationClicked(checkEntry) {
      const request = {
        location: checkEntry.location,
        checkID: this.selectedCheck.id,
      };
      this.addConfirmedLocation(request);
    },
    modifyClicked(checkEntry) {
      const request = {
        location: checkEntry.location,
        equipment: checkEntry.equipment,
        checkID: this.selectedCheck.id,
      };
      if (checkEntry.status === checkStatuses.CONFIRMED && checkEntry.location.equipment.length === 1) {
        this.removeConfirmedEntry(request);
      } else if (checkEntry.status === checkStatuses.CONFIRMED && checkEntry.location.equipment.length === 0) {
        this.removeConfirmedLocation(request);
      }
    },
    editClicked(checkEntry) {
      const gateEntry = {};
      gateEntry.direction = direction.IN;
      gateEntry.location = _.cloneDeep(checkEntry.location);
      gateEntry.equipment = _.cloneDeep(checkEntry.equipment);
      this.setGateEntry(gateEntry);
      this.setShowEditEquipmentDialog(true);
    },
    deleteLostEquipmentClicked() {
      this.setShowDeleteLostEquipmentDialog(true);
    },
    groupSelected(group) {
      this.pageGroup = group;
      this.$vuetify.goTo(
        '.data-table tbody tr',
        {
          offset: 50,
          container: '.v-data-table__wrapper',
        },
      );
    },
    async reloadCheckEntries() {
      await this.refreshCheckEntries();
    },
    async loadLostEquipment() {
      await this.getLostEquipment();
    },
    async loadActiveChecks() {
      await this.getActiveChecks(true);
    },
    initialize() {
      this.setSelectedGroup(this.groupNames[0]);

      if (!_.isEmpty(this.checkEntries)) {
        this.loadLostEquipment();
        this.reloadCheckEntries();
      } else {
        this.loadLostEquipment();
        this.loadActiveChecks();
      }
    },
  },
  watch: {
    locationIsLoading(isLoading) {
      if (!isLoading) {
        this.initialize();
      }
    },
    isYardSocketConnected(isConnected) {
      if (!isConnected) {
        const intervalTime = 60000; // 1 minute;

        this.interval = setInterval(() => {
          this.loadLostEquipment();
          this.reloadCheckEntries();
        }, intervalTime);
      } else {
        clearInterval(this.interval);
      }
    },
  },
  mounted() {
    if (!this.isPageLoading) {
      this.initialize();
    }
    if (_.isEmpty(this.selectedCheck)) {
      this.setIsLoading(true);
    }
  },
  beforeDestroy() {
    this.setFilter('');
    clearInterval(this.interval);
  },
};
</script>

<style>
  #expanded-area {
    border-bottom: thin solid rgba(0, 0, 0, 0.15);
  }

  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) >
  td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0);
}
</style>
