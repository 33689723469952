<template>
  <v-btn color="primary" min-width="8vw" @click="submitClicked">Submit</v-btn>
</template>

<script>
export default {
  methods: {
    submitClicked() {
      this.$emit('submit');
    },
  },
};
</script>
