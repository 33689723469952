var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VerifyEquipmentEntryDialog',{on:{"verify-equipment-dialog-closed":_vm.verifyEquipmentDialogClosed}}),_c('v-combobox',{attrs:{"solo":"","clearable":"","items":_vm.knownEquipment,"item-text":"number","label":"Equipment Number Search","menu-props":{maxHeight: 150}},on:{"click:clear":_vm.clearForm},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',[_vm._v(" Does Not Exist")])])]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.number)+" ")])]):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.number)+" ")]),_c('span',{staticClass:"ml-1",attrs:{"label":""}},[_vm._v(" Type: "+_vm._s(item.type)+" |")]),_c('span',{staticClass:"ml-1",attrs:{"label":""}},[_vm._v(" Size: "+_vm._s(item.size)+" |")]),_c('span',{staticClass:"ml-1",attrs:{"label":""}},[_vm._v(" State: "+_vm._s(item.state)+" |")]),_c('v-spacer')]}}]),model:{value:(_vm.equipmentNumber),callback:function ($$v) {_vm.equipmentNumber=$$v},expression:"equipmentNumber"}}),_c('v-form',{ref:"formEntry"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('EquipmentTypeEntry',{attrs:{"disabled":!_vm.isNewEquipment}})],1),_c('v-row',[_c('EquipmentStateEntry')],1),_c('v-row',[_c('EquipmentNumberEntry',{attrs:{"disabled":!_vm.isNewEquipment,"allowAutocomplete":false}})],1),_c('v-row',[_c('EquipmentSizeEntry')],1),_c('v-row',{staticClass:"pt-0 mt-0"},[_c('EquipmentCarrierEntry')],1),_c('v-row',[_c('v-col',{staticClass:"pl-0 pt-0 mt-0"},[_c('EquipmentBookingNumberEntry')],1),_c('v-col',{staticClass:"pl-0 pt-0 mt-0"},[_c('EquipmentCustomerEntry')],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-0 py-0 my-0",attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"disabled":"","rules":_vm.equipmentLocationRules,"label":"Spot Number *"},model:{value:(_vm.spot),callback:function ($$v) {_vm.spot=$$v},expression:"spot"}})],1)],1),_c('v-row',[_c('v-col',[(!_vm.showEditEquipmentDialog)?_c('submit-button',{staticClass:"mt-n1 mb-0 pt-n1 mb-0",on:{"submit":_vm.submit}}):_c('edit-location-equipment-dialog-buttons',{staticClass:"mt-n1 mb-0 pt-n1 mb-0",on:{"equipmentConfirmed":_vm.equipmentConfirmed,"update":_vm.update}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }