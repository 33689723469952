import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import direction from '../constants/direction';

export default {
  data: () => ({

  }),
  computed: {
    isPageLoading() {
      return this.isLoading || this.locationIsLoading;
    },
    ...mapGetters('check', ['canCheck', 'selectedCheck', 'isLoading']),
    ...mapGetters({
      expandedCheckEntries: 'checkView/expandedCheckEntries',
      expandedCheckEntriesEquipment: 'checkView/expandedCheckEntriesEquipment',
      equipmentLocation: 'location/equipmentLocation',
      locationIsLoading: 'location/isLoading',
    }),

  },
  methods: {
    ...mapActions({
      addConfirmedEquipment: 'check/addConfirmedEquipment',
      addLostEquipment: 'check/addLostEquipment',
      addConfirmedLocation: 'check/addConfirmedLocation',
      removeConfirmedEntry: 'check/removeConfirmedEquipment',
      removeConfirmedLocation: 'check/removeConfirmedLocation',
      setGateEntry: 'gate/setGateEntry',
      setShowEditEquipmentDialog: 'location/setShowEditEquipmentDialog',
    }),
    addClicked(checkEntry) {
      const gateEntry = {};
      gateEntry.direction = direction.IN;
      gateEntry.location = _.cloneDeep(checkEntry.location);
      gateEntry.equipment = _.cloneDeep(checkEntry.equipment);
      this.setGateEntry(gateEntry);
      this.setShowEditEquipmentDialog(true);
    },
    confirmClicked(equipment) {
      const request = {
        location: this.equipmentLocation(equipment.id),
        equipment,
        checkID: this.selectedCheck.id,
      };
      this.addConfirmedEquipment(request);
    },
    async lostClicked(equipment) {
      const request = {
        location: this.equipmentLocation(equipment.id),
        equipment,
        checkID: this.selectedCheck.id,
      };
      await this.addLostEquipment(request);
    },
  },

};
