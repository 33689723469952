<template>
    <div>
    <v-data-table
        id="locationEquipmentDataTable"
        loading-text="Loading... Please wait"
        hide-default-footer
        :headers="headers"
        :items="checkEntry.location.equipment"
        :expanded="expandedCheckEntriesEquipment"
        :fixed-header="true"
        :loading="isPageLoading"
        :items-per-page="-1"
        @click:row="(item, slot) => {slot.expand(!slot.isExpanded); modifyEquipmentClicked(item)}"
        >
        <template
            v-slot:expanded-item="{ headers, item }"
            color="primary"
        >
            <td
                id="expanded-area"
                :colspan="headers.length"
                bgcolor="white"
            >
            <v-btn
                v-if="shouldShowEquipmentConfirmButton(item)"
                class="mr-4 mb-4 mt-0"
                color="primary"
                min-width="8vw"
                @click="confirmClicked(item)"
            >
                Confirm
            </v-btn>
            <v-btn
                v-if="shouldShowEquipmentLostButton(item)"
                class="mr-4 mb-4 mt-0"
                color="secondary"
                min-width="8vw"
                @click="lostClicked(item)"
            >
                Lost
            </v-btn>
            <v-btn
                v-if="shouldShowEditButton(item)"
                class="mr-4 mb-4 mt-0"
                color="neutral"
                min-width="8vw"
                @click="editClicked(item, checkEntry)"
            >
                Edit
            </v-btn>
            </td>
        </template>
        <template v-slot:body.append="{ }">
            <td
            :colspan="headers.length"
            >
            <v-btn
                v-if="shouldShowConfirmRemainingEmptyButton(checkEntry)"
                id="confirmRemainingEmptyButton"
                class="ml-4 mb-4 mt-4"
                color="primary"
                min-width="8vw"
                @click="confirmRemainingEmptyClicked(checkEntry)"
            >
                CONFIRM REMAINING EMPTY
            </v-btn>

            <v-btn
                class="ml-4 mb-4 mt-4"
                :style="{backgroundColor:addButtonColor(checkEntry)}"
                min-width="8vw"
                @click="addClicked(checkEntry)"
            >
                Add
            </v-btn>
            </td>
        </template>
        </v-data-table>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import checkMixins from '../mixins/checkMixins';
import checkStatuses from '../constants/checkStatuses';
import direction from '../constants/direction';

export default {
  props: ['checkEntry'],
  mixins: [checkMixins],
  data: () => ({
    headers: [
      { text: 'Status', value: 'status' },
      { text: 'Equipment Number', value: 'number' },
      { text: 'Type', value: 'type' },
      { text: 'Size', value: 'size' },
      { text: 'State', value: 'state' },
      { text: 'SCAC', value: 'carrier.scac' },
      { text: 'Carrier', value: 'carrier.name' },
      { text: 'Booking #', value: 'bookingNumber' },
      { text: 'Customer', value: 'customer.name' },
    ],
  }),
  methods: {
    ...mapActions({
      setLocationStatus: 'location/setLocationStatus',
    }),
    shouldShowConfirmRemainingEmptyButton(checkEntry) {
      if (checkEntry.location.equipmentCapacity - checkEntry.location.equipment.length > 0) {
        for (let i = 0; i < checkEntry.location.equipment.length; i += 1) {
          if (checkEntry.location.equipment[i].status !== checkStatuses.CONFIRMED) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    shouldShowEquipmentConfirmButton(item) {
      return (item.status !== checkStatuses.CONFIRMED && item.id !== undefined);
    },
    shouldShowEquipmentLostButton(item) {
      return item.status !== checkStatuses.CONFIRMED && item.id !== undefined;
    },
    shouldShowEditButton(item) {
      let isEditable = false;
      const hasUnsetStatus = !item.status || item.status === '';
      if (hasUnsetStatus) {
        isEditable = true;
      }
      return isEditable;
    },
    editClicked(equipment, checkEntry) {
      const gateEntry = {};
      gateEntry.direction = direction.IN;
      gateEntry.location = _.cloneDeep(checkEntry.location);
      gateEntry.equipment = _.cloneDeep(equipment);
      this.setGateEntry(gateEntry);
      this.setShowEditEquipmentDialog(true);
    },
    confirmRemainingEmptyClicked(checkEntry) {
      const request = {
        location: checkEntry.location,
        checkID: this.selectedCheck.id,
      };
      this.addConfirmedLocation(request);
    },
    modifyEquipmentClicked(equipment) {
      if (equipment.status === checkStatuses.CONFIRMED) {
        const request = {
          location: this.equipmentLocation(equipment.id),
          equipment,
          checkID: this.selectedCheck.id,
        };
        this.removeConfirmedEntry(request);
      }
    },
    addButtonColor(checkEntry) {
      if (checkEntry.location.equipmentCapacity <= checkEntry.location.equipment.length) {
        return this.$vuetify.theme.themes.light.secondary;
      }
      return this.$vuetify.theme.themes.light.neutral;
    },
  },
};
</script>

<style>
#locationEquipmentDataTable {
    border: thin solid rgba(0, 0, 0, 1.0);
  }
</style>
