export default {
  methods: {
    getScacOrCarrierName(carrier) {
      if (carrier && carrier.scac) {
        return carrier.scac;
      }
      if (carrier && carrier.name) {
        return carrier.name;
      }
      return '';
    },
    carrierFilter(value, search, item) {
      return item.carrier.scac === value;
    },
  },
};
