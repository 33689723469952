<template>
  <v-dialog v-model="show" max-width="600" persistent>
    <v-card>
      <v-card-title>
        Yard Check Summary - {{ formatDate(selectedCheck.startTime) }}
      </v-card-title >
      <v-container>
        <v-row>
          <v-col cols="auto" class="pl-8">
            <span><strong><u>Equipment Not Found Shown Below:</u></strong></span>
          </v-col>
          <v-col cols="auto">
            <download-excel
              class="btn btn-default"
              :data="lostEquipment"
              :export-fields="exportFields"
              type="csv"
              :name="`YardCheckLost - ${formatDate(selectedCheck.startTime)}.xls`"
            >
              <v-btn class="primary" @click="hasDownloadedCSV = true">
                Download CSV
              </v-btn>
            </download-excel>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="lostEquipment"
            height="500"
            disable-pagination
            hide-default-footer
            fixed-header
            style="padding-left: 25px; padding-top: 10px"
          >
            <template v-slot:item.scacCarrier="{ item }">
              <td>{{ getScacOrCarrierName(item) }}</td>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="closeClicked"> Close </v-btn>
        <v-btn
          color="primary"
          outlined
          :disabled="!hasDownloadedCSV && lostEquipment.length != 0"
          @click="endCheckClicked"
        >
          End Check
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dateMixins from '../mixins/dateMixins';
import carrierMixin from '../mixins/carrierMixins';

export default {
  mixins: [dateMixins, carrierMixin],
  data: () => ({
    headers: [
      { text: 'Equipment Number', value: 'equipment.number' },
      { text: 'Type', value: 'equipment.type' },
    ],
    exportFields: {
      'Equipment Number': 'equipment.number',
      'Equipment Type': 'equipment.type',
    },
    hasDownloadedCSV: false,
  }),
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        if (!value) {
          this.setShowDialog(false);
        }
      },
    },
    ...mapGetters('verifyYardCheck', ['showDialog']),
    ...mapGetters('check', ['selectedCheck', 'lostEquipment']),
    ...mapGetters('account', ['fullName']),
  },
  methods: {
    ...mapActions('verifyYardCheck', ['setShowDialog']),
    ...mapActions('check', ['stopCheck']),
    endCheckClicked() {
      const check = this.selectedCheck;
      check.endTime = new Date().toISOString();
      check.endedBy = this.fullName;
      this.stopCheck(check);
      this.reset();
    },
    closeClicked() {
      this.reset();
    },
    reset() {
      this.hasDownloadedCSV = false;
      this.show = false;
    },
  },
};
</script>
