<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="searchTerm"
        append-icon="mdi-magnify"
        label="Search lost equipment..."
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-simple-table height="50vh" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              Equipment Number
            </th>
            <th class="text-center">
              Equipment Type
            </th>
            <th class="text-center">
              Equipment Size
            </th>
            <th class="text-center">
              State
            </th>
            <th class="text-center">
              Date Lost
            </th>
            <th class="text-center">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in lostEquipmentTableData"
            :key="item.id"
          >
            <td class="text-center">{{ item.equipment.number }}</td>
            <td class="text-center">{{ item.equipment.type }}</td>
            <td class="text-center">{{ item.equipment.size }}</td>
            <td class="text-center">{{ item.equipment.state }}</td>
            <td class="text-center">{{ item.insertedDate }}</td>
            <td class="text-center"><v-btn @click="deleteClicked(item)">DELETE</v-btn></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    searchTerm: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setFilter(value);
      },
    },
    lostEquipmentTableData() {
      if (this.searchTerm.length > 1) {
        return this.filteredLostEquipment(this.searchTerm);
      }

      return this.lostEquipment;
    },
    ...mapGetters('check', ['lostEquipment', 'filteredLostEquipment']),
    ...mapGetters('lostYardCheck', ['filter']),
  },
  methods: {
    ...mapActions('lostYardCheck', ['setFilter']),
    ...mapActions('check', ['removeLostEquipment', 'setShowDeleteLostEquipmentDialog']),
    deleteClicked(lostEquipment) {
      this.searchTerm = '';
      this.removeLostEquipment(lostEquipment.equipment);
    },
  },
};
</script>
