<template>
  <v-dialog :persistent="true" v-model="showDeleteLostEquipmentDialog" max-width="800">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row class="justify-center">
              <v-col cols="12">
                  <span id="updateTitle">Lost Equipment List:</span>
              </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text v-if="isLoading" id="isLoadingCardText">
        <div>
          <h2 class="text-center">Saving...</h2>
          <v-progress-circular
            indeterminate
            size="100"
            class="offset-md-5"
            style="overflow: hidden"
          ></v-progress-circular>
        </div>
      </v-card-text>
      <v-card-text v-show="!isLoading" id="isNotLoadingCardText">
        <v-container fluid flex>
          <v-row class="justify-center">
            <v-col cols="12">
              <lostEquipment></lostEquipment>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-btn @click="closeClicked()">Close</v-btn>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import lostEquipment from './lostEquipment.vue';

export default {
  components: { lostEquipment },
  computed: {
    isLoading() {
      return this.saveIsProcessing || this.updateIsProcessing;
    },
    ...mapGetters({
      showDeleteLostEquipmentDialog: 'check/showDeleteLostEquipmentDialog',
      saveIsProcessing: 'gate/isLoading',
      updateIsProcessing: 'location/isLoading',
    }),
  },
  methods: {
    ...mapActions('check', ['setShowDeleteLostEquipmentDialog']),
    closeClicked() {
      this.setShowDeleteLostEquipmentDialog(false);
    },
  },
};
</script>
