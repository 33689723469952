<template>
  <v-dialog :persistent="true" v-model="showEditEquipmentDialog" max-width="800">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row class="justify-center">
            <v-col cols="12">
              <span id="updateTitle" v-if="gateEntry.equipment.id">Update Equipment Information Below:</span>
              <span id="addTitle" v-if="!gateEntry.equipment.id">Add Equipment Information Below:</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text v-if="isLoading" id="isLoadingCardText">
        <div>
          <h2 class="text-center">Saving...</h2>
          <v-progress-circular
            indeterminate
            size="100"
            class="offset-md-5"
            style="overflow: hidden"
          ></v-progress-circular>
        </div>
      </v-card-text>
      <v-card-text v-show="!isLoading" id="isNotLoadingCardText">
        <v-container fluid flex>
          <v-row class="justify-center">
            <v-col cols="12">
              <span v-if="gateEntry.direction">
                <equipment-check-entry></equipment-check-entry>
              </span>
              <span v-if="!gateEntry.direction">
                <edit-equipment></edit-equipment>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import equipmentCheckEntry from './equipmentCheckEntry.vue';
import editEquipment from './editEquipmentDialog.vue';

export default {
  components: { equipmentCheckEntry, editEquipment },
  computed: {
    isLoading() {
      return this.saveIsProcessing || this.updateIsProcessing || this.addEquipmentIsProcessing;
    },
    ...mapGetters({
      gateEntry: 'gate/gateEntry',
      showEditEquipmentDialog: 'location/showEditEquipmentDialog',
      saveIsProcessing: 'gate/isLoading',
      updateIsProcessing: 'location/isLoading',
      addEquipmentIsProcessing: 'check/isAddingEquipmentToLocation',
    }),
  },
};
</script>
