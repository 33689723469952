<template>
    <div>
      <VerifyEquipmentEntryDialog @verify-equipment-dialog-closed="resetFormValidation" />
      <v-form ref="formEntry">
        <v-container fluid>
          <v-row>
            <EquipmentTypeEntry
              v-bind:disabled="!isNewEquipment"/>
          </v-row>
          <v-row>
            <EquipmentStateEntry />
          </v-row>
          <v-row>
            <EquipmentNumberEntry
              v-bind:disabled="!isNewEquipment"
            />
          </v-row>
          <v-row>
            <EquipmentSizeEntry />
          </v-row>
          <v-row class="pt-0 mt-0">
            <EquipmentCarrierEntry />
          </v-row>
          <v-row>
            <v-col class="pl-0 pt-0 mt-0">
              <EquipmentBookingNumberEntry />
            </v-col>
            <v-col class="pl-0 pt-0 mt-0">
              <EquipmentCustomerEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <EditLocationEquipmentDialogButtons
                v-on:update="updateButtonClicked"
                class="mt-n1 mb-0 pt-n1 mb-0"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import EditLocationEquipmentDialogButtons from './editLocationEquipmentDialogButtons.vue';
import equipmentTypes from '../constants/equipmentTypes';
import EquipmentTypeEntry from './equipmentTypeEntry.vue';
import EquipmentStateEntry from './equipmentStateEntry.vue';
import EquipmentNumberEntry from './equipmentNumberEntry.vue';
import EquipmentSizeEntry from './equipmentSizeEntry.vue';
import EquipmentCarrierEntry from './equipmentCarrierEntry.vue';
import EquipmentBookingNumberEntry from './equipmentBookingNumberEntry.vue';
import EquipmentCustomerEntry from './equipmentCustomerEntry.vue';
import VerifyEquipmentEntryDialog from './verifyEquipmentEntryDialog.vue';

export default {
  components: {
    EditLocationEquipmentDialogButtons,
    EquipmentTypeEntry,
    EquipmentStateEntry,
    EquipmentNumberEntry,
    EquipmentSizeEntry,
    EquipmentCarrierEntry,
    EquipmentBookingNumberEntry,
    EquipmentCustomerEntry,
    VerifyEquipmentEntryDialog,
  },
  data: () => ({
    equipmentTypes: equipmentTypes.ALL_TYPES,
    newEquipment: true,
  }),
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    isNewEquipment() {
      if (this.gateEntry.equipment.id !== '') {
        return false;
      }
      return this.newEquipment;
    },
  },
  methods: {
    ...mapActions('gate', ['resetState']),
    ...mapActions('location', ['updateEquipment']),
    resetFormValidation() {
      this.$refs.formEntry.resetValidation();
    },
    async updateButtonClicked() {
      const isValid = this.$refs.formEntry.validate();
      if (isValid) {
        this.$refs.formEntry.resetValidation();
        await this.updateEquipment({
          location: _.cloneDeep(this.gateEntry.location),
          equipment: _.cloneDeep(this.gateEntry.equipment),
        });
      }
    },
  },
  beforeDestroy() {
    this.resetState();
  },
};
</script>
