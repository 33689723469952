function hasPrefix(val) {
  if (val < 10) {
    return true;
  }
  return false;
}

export default {
  methods: {
    formatDate: (date) => {
      const d = new Date(date);
      return d.toLocaleString('en-US', { timeZone: 'America/New_York' });
    },
    convertDateToTimeString(date) {
      const dateNow = new Date(date);
      const dateFuture = new Date();

      let seconds = Math.floor((dateFuture - (dateNow)) / 1000);
      let minutes = Math.floor(seconds / 60) * 1.0;
      let hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      hours -= (days * 24);
      minutes = minutes - (days * 24 * 60) - (hours * 60);
      seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

      let hoursPrefix = '';
      if (hasPrefix(hours)) {
        hoursPrefix = '0';
      }
      let minutesPrefix = '';
      if (hasPrefix(minutes)) {
        minutesPrefix = '0';
      }
      let secondsPrefix = '';
      if (hasPrefix(seconds)) {
        secondsPrefix = '0';
      }

      return `${hoursPrefix}${hours}:${minutesPrefix}${minutes}:${secondsPrefix}${seconds}`;
    },
  },
};
