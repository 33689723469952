<template>
    <v-container class="ml-0">
    <v-row>
      <v-radio-group
        v-model="carrierOption"
        v-on:change="scacOptionChanged"
        row>
        <v-radio value="SCAC" label="SCAC">SCAC</v-radio>
        <v-radio value="Carrier" label="Carrier">Carrier</v-radio>
      </v-radio-group>
      <v-text-field
        v-model="scacOrCarrier"
        label="SCAC / Carrier"
      ></v-text-field>
    </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    carrierOption: 'SCAC',
  }),
  computed: {
    ...mapGetters('gate', ['gateEntry']),
    scacOrCarrier: {
      get() {
        if (this.gateEntry.equipment.carrier) {
          if (this.carrierOption === 'SCAC') {
            return this.gateEntry.equipment.carrier.scac;
          }
          return this.gateEntry.equipment.carrier.name;
        }
        return '';
      },
      set(val) {
        if (this.carrierOption === 'SCAC') {
          this.setCarrierSCAC(val.toUpperCase());
        } else {
          this.setCarrierName(val);
        }
      },
    },
  },
  methods: {
    ...mapActions('gate', ['setCarrierSCAC', 'setCarrierName']),
    scacOptionChanged() {
      this.setCarrierName(null);
      this.setCarrierSCAC(null);
    },
  },
};
</script>
